<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

        <!-- Table Top -->
        <b-row class="pt-2 pl-2 pr-2">  
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4>Data Kambing Beranak</h4>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
          </b-col>
        </b-row>
        <hr/>
        <b-row class="pl-2 pr-2 pb-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              
              
            </div>
          </b-col>
        </b-row>

      
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchPregnant"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <!-- <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template> -->
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          
          <b-link
            :to="{ name: 'giveBirthDetail', params: { id: data.item.id } }">
            <feather-icon icon="EyeIcon" />
          </b-link>
          
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalGoats"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useAnimalPregnantList from './useAnimalPregnantList'
import animalPregnantStoreModule from './animalPregnantStoreModule'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    BFormDatepicker,

    vSelect,
  },
  data() {
    return {
      resultOptions: [
        { label: 'Berhasil Melahirkan', value: 'success', disabled: false},
        { label: 'Gagal', value: 'failed', disabled:false},
      ],
    }
  },
  setup() {
    const PREGNANT_APP_STORE_MODULE_NAME = 'app-goat-pregnant'

    // Register module
    if (!store.hasModule(PREGNANT_APP_STORE_MODULE_NAME)) store.registerModule(PREGNANT_APP_STORE_MODULE_NAME, animalPregnantStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PREGNANT_APP_STORE_MODULE_NAME)) store.unregisterModule(PREGNANT_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPregnant,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      //ui
      resolveGenderAnimalVariant,

      // Extra Filters
    } = useAnimalPregnantList()

    // update status bunting
    const showModalUpdateStatus = ref(false);
    const selectedResultMating= ref('');

    const blankSelectedMating = {
      id: null,
      female_animal: null,
      birth_date_realization: null,
      next_mating_date: null,
      description: null,
      birth_place: 'SMKN 1 Tulung Klaten',
      children_amount: 0,
      colostrum_volume: 0,
      end_of_postpartum: null,
      pen_id: null,   
    }

    // set birthdate realization today
    blankSelectedMating.birth_date_realization = new Date();

    const selectedMating = ref(JSON.parse(JSON.stringify(blankSelectedMating)))
    
    const setSelectedMating = (id, betina) => {
      selectedMating.value.id = id
      selectedMating.value.female_animal = betina
    }

     // when click simpan status
     const onSubmitModal = () => {
        // check status 

        toast({
          component: ToastificationContent,
          props: {
            title: 'Hasil Perkawinan Berhasil Diperbarui',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
        showModalUpdateStatus.value = !showModalUpdateStatus.value;
    }

    const onCancelModal = () => {
        // check status 
        selectedResultMating.value = '';
        showModalUpdateStatus.value = !showModalUpdateStatus.value;
    }

    return {

      // modal
      showModalUpdateStatus,
      selectedMating,
      selectedResultMating,
      setSelectedMating,
      onSubmitModal,
      onCancelModal,

      fetchPregnant,
      tableColumns,
      perPage,
      currentPage,
      totalGoats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      //ui
      resolveGenderAnimalVariant,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
