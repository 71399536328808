import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGoatsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'betina', sortable: true },
    { key: 'tgl_beranak', sortable: true },
    { key: 'tempat_beranak', sortable: true },
    { key: 'kandang', sortable: true },
    { key: 'jml_anak', sortable: true },
    { key: 'jml_kolostrum', sortable: true },
    { key: 'tgl_akhir_nifas', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalGoats = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGoats.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery,], () => {
    refetchData()
  })

  const mapServiceGiveBirthToModel = ({
    id, 
    mating_id, 
    number_of_babies, 
    colostrum_volume, 
    end_of_postpartum, 
    animal_female, 
    birth_date_realization, 
    pen, 
    place,
  }) => ({
    id,
    mating_id,
    jml_anak: number_of_babies,
    jml_kolostrum: colostrum_volume,
    tgl_akhir_nifas:end_of_postpartum,
    betina: animal_female,
    kandang:pen,
    tempat_beranak: place,
    tgl_beranak: birth_date_realization,
  })

  const fetchPregnant = (ctx, callback) => {
    store
      .dispatch('app-goat-pregnant/fetchPregnant')
      .then(response => {
        const data = response.data.data
        const dataMapped = data.map(mapServiceGiveBirthToModel)
        const { total } = response.data.data
        
        callback(dataMapped)
        totalGoats.value = total
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching goats list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPregnant,
    tableColumns,
    perPage,
    currentPage,
    totalGoats,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

  }
}
